import * as React from "react"
import { Header, SingleVideo, Footer, BottomNavbar } from "../components/index"
import { Helmet } from "react-helmet"

import mockup from "../utils/data"
import SubTitle from "../components/Common/SubTitle/SubTitle"
import Container from "../components/Common/Container/Container"

const IndexPage = () => {
  return (
    <div>
      <Helmet>
        <title>Esteekey - Single Videos</title>
        <meta name="description" content="Esteekey - #1 Fitness app for 2022" />
        <meta name="theme-color" content="#ffffff" data-react-helmet="true" />
      </Helmet>
      <Header />
      <div className="min-h-[calc(100vh-158px)] pt-[69px] xl:pt-0">
        <SingleVideo
          title={mockup.single_video.title}
          overview={mockup.single_video.overview}
          tags={mockup.single_video.tags}
          videoUrl={mockup.single_video.video_url}
          videoPreview={mockup.single_video.video_preview}
          url={mockup.single_video.url}
          list={mockup.single_video.download_list}
        />
      </div>
      <BottomNavbar />
      <Footer />
    </div>
  )
}

export default IndexPage
